import { Typography } from "@mui/material";
import React from "react";

export interface ConsentTermsProps {}

export const ConsentTerms: React.FC<ConsentTermsProps> = ({}) => {
  return (
    <>
      <Typography variant="h5" paragraph>
        Introduction
      </Typography>
      <Typography paragraph>
        Inuvialuit Residential Schools Missing Children (IRSMC) is a new project
        under the Inuvialuit Regional Corporation (IRC) to find the remains of
        Inuvialuit children who attended residential schools and who never
        returned home. The IRSMC project enables individuals and families to
        request or provide information for family members who were sent to
        residential schools between 1915 and 1996 and went missing. The project
        will provide a range of supports to individuals and families.
      </Typography>
      <Typography>IRSMC PROJECT GOALS</Typography>
      <ul>
        <Typography component="li">
          Search and identify places where Inuvialuit children who never
          returned home are buried.
        </Typography>
        <Typography component="li">
          Memorialize the children by installing headstones and placing
          appropriate grave markers.
        </Typography>
        <Typography component="li">
          Collaborate with and be guided by survivors, families, elders and
          other leadership.
        </Typography>
        <Typography component="li">
          Provide safe and respectful mental health and emotional support
          services to those involved.
        </Typography>
        <Typography component="li">
          Host memorial ceremonies for each family whose loved one's resting
          place was identified.
        </Typography>
        <Typography component="li">
          Offer closure to families and community members.
        </Typography>
      </ul>
      <Typography paragraph>
        The development of IRC’s secure IRSMC database and other supports under
        the IRSMC project were guided by the Government of Canada’s Contribution
        Agreement for Residential Schools Missing Children Community Support
        Funding Initiative.
      </Typography>
      <Typography paragraph>
        For individuals to participate in the IRSMC project, IRC requires your
        consent to collect, use and disclose certain personal information about
        you. IRC will collect, use and disclose your personal information in
        accordance with the Personal Information Protection and Electronic
        Documents Act, S.C. 2000, c.5.
      </Typography>
      <Typography variant="h5" paragraph>
        Collection, Use and Disclosure of Your Personal Information
      </Typography>
      <Typography paragraph>
        IRC will collect, use and disclose your personal information to assist
        you with your request for information or supports under the IRSMC
        project.
      </Typography>
      <Typography paragraph>
        IRC will collect your personal information and information about your
        family to open a file within IRC’s internal database under your name in
        order to keep all information related to your inquiry organized and
        securely stored in a central location.
      </Typography>
      <Typography paragraph>
        IRC will use your personal information and information about your family
        consistent with your participation in the IRSMC project, namely:
      </Typography>
      <ul>
        <Typography component="li">
          to submit requests to the federal government for documents or
          information related to you or your family members, including but not
          limited to the National Truth and Reconciliation Database;
        </Typography>
        <Typography component="li">
          to submit requests to provincial or territorial governments for
          documents or information related to you or your family;
        </Typography>
        <Typography component="li">
          to submit requests to other third-party entities for documents or
          information to you or your family, including but not limited to other
          family members, health authorities, hospitals, municipalities,
          archives, libraries, cemeteries and funeral homes.
        </Typography>
        <Typography component="li">
          to evaluate whether services can be provided to you, including but not
          limited to services related to commemorative events and monuments,
          travel, gravesite enhancement, health and counselling;
        </Typography>
        <Typography component="li">
          to further IRC's mandates under the Inuvialuit Final Agreement,
          including lobbying and funding requests for federal, provincial and
          territorial governments, for statistical, scholarly study or research
          purposes, and for internal IRC program development; and
        </Typography>
        <Typography component="li">
          for reporting purposes as may be required by any funding contribution
          agreements.
        </Typography>
      </ul>
      <Typography paragraph>
        IRC may also disclose your personal information to other third parties
        in certain circumstances not related to the IRSMC project:
      </Typography>
      <ul>
        <Typography component="li">
          to respond to law enforcement requests, including but not limited to
          complying with warrants, subpoenas, or production orders or other
          legally binding orders from a court or investigatory authority. IRC
          will not provide information except where required by applicable law;
          and
        </Typography>
        <Typography component="li">
          to enforce IRC's rights, including but not limited to the event of a
          breach of an agreement or contravention of law. For example, IRC will
          use any consents or authorizations you provide to confirm its
          authority to collect, use and disclose your personal information in
          the event of any allegation of a privacy breach or in response to any
          related offences alleged against IRC or its agents.
        </Typography>
      </ul>
      <Typography variant="h5" paragraph>
        Safeguards, Retention and Destruction
      </Typography>
      <Typography paragraph>
        IRC has implemented policies and procedures, as well as physical and
        technical security precautions to prevent unauthorized access, use,
        modification or disclosure of personal information that it collects and
        uses.
      </Typography>
      <Typography paragraph>
        We retain your personal information relating to your participation in
        the Inuvialuit Residential Schools Missing Children project
        indefinitely. You may request access to personal information or request
        that IRC destroy any personal information it holds by making a written
        request to IRC at the contact details below. In the event of destruction
        of personal information, IRC may retain some information in a
        de-identified form for research purposes.
      </Typography>
      <Typography variant="h5" paragraph>
        Complaints
      </Typography>
      <Typography paragraph>
        You have the right to file a complaint to the Office of the Privacy
        Commissioner of Canada regarding IRC's handling of your personal
        information. To do so, please contact the Privacy Commissioner at
        1-800-282- 1376 or visit{" "}
        <a href="https://www.priv.gc.ca/en/">https://www.priv.gc.ca/en/</a>.
      </Typography>
      <Typography variant="h5" paragraph>
        How to Contact IRC About Your Personal Information
      </Typography>
      <Typography paragraph>
        If you have further questions, would like to make a complaint, or would
        like assistance accessing or requesting your personal information be
        destroyed, please contact IRC's Privacy Officer by email at
        it@inuvialuit.com.
      </Typography>
    </>
  );
};
