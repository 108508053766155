import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { TextFormField } from "../../common/components/FormFields";
import { MuiLocalHospital, MuiMap, MuiPerson } from "../../common/icons";

export interface IndividualFieldsProps {}

export const IndividualFields: React.FC<IndividualFieldsProps> = ({}) => {
  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.up("sm"));
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      <Typography variant="h4">Information on Missing Loved One</Typography>
      <Grid container spacing={1}>
        <Grid item md={4} sm={6} xs={12}>
          <TextFormField
            name="individualFirstName"
            label="First Name"
            icon={<MuiPerson />}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <TextFormField
            name="individualLastName"
            label="Last Name"
            noIcon={sm}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextFormField
            name="individualNickname"
            label="Nickname"
            noIcon={md}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFormField name="discNumber" label="Disc Number" />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="landClaimAreaOfOrigin"
            label="Land Claim Area of Origin"
            icon={<MuiMap />}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="communityOfOrigin"
            label="Community/Camp of Origin"
            noIcon={sm}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField name="yearOfBirth" label="Year of Birth" />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField name="gender" label="Gender" noIcon={sm} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="ageAtResidentialSchool"
            label="Age at Residential School"
            icon={<MuiLocalHospital />}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="approximateAgeOfAdmittance"
            label="Approximate Date of Admittance"
            noIcon={sm}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="residentialSchoolSentTo"
            label="Residential School Loved One sent to"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextFormField
            name="modeOfTransportation"
            label="Mode of Transportation (e.g. ship/plane/train)"
            noIcon={sm}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFormField
            name="individualAdditionalInformation"
            label="Other Relevant Information"
            multiline
          />
        </Grid>
      </Grid>
    </Box>
  );
};
