import React from "react";
import { Box, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  DateFormField,
  InternationalAddressFields,
  TextFormField,
} from "../common/components/FormFields";
import MuiPeople from "@mui/icons-material/People";

export interface ParentFieldsProps {
  namespace: string;
  title: string;
  required?: boolean;
}

export function ParentFields({
  namespace,
  title,
  required,
}: ParentFieldsProps) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Box>
      <Box pb={1}>
        <Typography variant="h6">{title}</Typography>
      </Box>
      <Grid container spacing={0.5}>
        <Grid item md={4} xs={12}>
          <TextFormField
            name={`${namespace}.name`}
            label="Name"
            icon={<MuiPeople />}
            required={required}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <DateFormField
            name={`${namespace}.dateOfBirth`}
            label="Date of Birth"
            noIcon={md}
            required={required}
            stringOutput
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <TextFormField name={`${namespace}.nihb`} label="N#" noIcon={md} />
        </Grid>
      </Grid>
    </Box>
  );
}
