import { InquirySubmission } from "../../InquiryForm/InquiryForm";
import { sendPost } from "../networking/api";
import { apiResponse } from "./Interfaces/response";
import { IRSMCInquirySubmission } from "../../IRSMCInquiryForm/IRSMCInquiryForm";

export async function submitInquiry(
  inquiry: InquirySubmission,
  recaptchaToken: string,
  recaptchaAction: string,
): Promise<apiResponse> {
  return await sendPost("/api/inquiry/add", {
    inquiry,
    recaptchaToken,
    recaptchaAction,
  });
}

export async function submitIRSMCInquiry(
  inquiry: IRSMCInquirySubmission,
  recaptchaToken: string,
  recaptchaAction: string,
): Promise<apiResponse> {
  return await sendPost("/api/irsmc-inquiry/add", {
    inquiry,
    recaptchaToken,
    recaptchaAction,
  });
}
