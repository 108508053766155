import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import {
  InternationalAddressFields,
  TextFormField,
} from "../common/components/FormFields";

export interface HouseholdFieldsProps {}

export function HouseholdFields({}: HouseholdFieldsProps) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="h5">Household</Typography>
      </Grid>
      <Grid item md={6} xs={12}>
        <TextFormField
          name={`parents.contactNumber`}
          label="Contact Number"
          required
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <TextFormField
          name={`parents.email`}
          label="Email"
          noIcon={md}
          required
        />
      </Grid>
      <Grid item xs={12}>
        <InternationalAddressFields
          namespace={`parents.address`}
          label="Address"
        />
      </Grid>
    </Grid>
  );
}
